<template>
  <el-main class="rh-medform">
    <h1>Données personnelles</h1>
    <form-medecin ref="formMed" v-loading="medecinLoadStatus == 1">
    </form-medecin>
    <div>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </el-main>
</template>
<script>
import FormMedecin from "@/components/rh/ui/form/FormMedecin";
import { trimStringInObj, strNormalize } from "@/fonctions";

export default {
  components: { "form-medecin": FormMedecin },

  data() {
    return {
      idMed: "",
      loadCount: 0,
    };
  },
  created() {
    this.idMed = this.$route.params.idmed; // récupère le id du médecin dans l'url
    this.afficheData();
  },
  computed: {
    medecinLoadStatus() {
      return this.$store.state.medl.medecinLoadStatus;
    },
    medecinUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.medl.medecinUpdateStatus;
    },
  },
  methods: {
    afficheData() {
      if (this.medecinLoadStatus == 2) {
        const med = this.$store.state.medl.medecin;
        // charge les données dans le formulaire
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.medloaded = JSON.stringify(med);
      } else if (this.medecinLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    onSubmit() {
      this.$refs.formMed.$refs.formMedecin.validate((valid) => {
        if (valid) {
          var medata = this.$store.state.medl.medecin;
          // enlever les espaces avant et après dans les string
          trimStringInObj(medata);
          // converti l'obj et string pour la comparaison
          var medataStr = JSON.stringify(medata);
          // vérifie si la personne est  déjà dans la bd avec un autre id
          var id = this.idMed;
          var MedsInBD = this.$store.getters["medl/MedsInBD"];
          var noChuv = parseInt(medata.no_employe);
          var nosChuvBD = this.$store.getters["medl/nosChuv"];
          if (localStorage.medloaded == medataStr) {
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
          } else if (
            MedsInBD.filter(
              (m) =>
                m[0] != id &&
                m[1] == strNormalize(medata.nom) &&
                m[2] == strNormalize(medata.prenom) &&
                m[3] == medata.naissance_date
            ).length > 0
          ) {
            const alertMsg = medata.naissance_date
              ? "<b>" +
                medata.nom +
                " " +
                medata.prenom +
                "</b> né le <b>" +
                medata.naissance_date +
                "</b> est déjà inscrit dans la base de données !"
              : "<b>" +
                medata.nom +
                " " +
                medata.prenom +
                "</b> est déjà inscrit dans la base de données ! S'il s'agit d'un homonyme, renseignez la <b>date de naissance</b>.";
            this.$alert(alertMsg, "Attention", {
              confirmButtonText: "Fermer",
              dangerouslyUseHTMLString: true,
            });
            return false;
          } else if (noChuv && nosChuvBD) {
            // vérifie si le No CHUV est déjà dans la BD
            if (
              nosChuvBD.filter((m) => m[0] != id && m[1] == noChuv).length > 0
            ) {
              this.$alert(
                "Ce 'No CHUV' est déjà attribué à une autre personne dans la base de données !",
                "Attention",
                {
                  confirmButtonText: "Fermer",
                }
              );
              return false;
            } else {
              // si plus d'erreur maj l'enregistrement
              this.$store.dispatch("medl/updateMedecin", medata);
              localStorage.medloaded = medataStr;
            }
          } else {
            // si plus d'erreur maj l'enregistrement
            this.$store.dispatch("medl/updateMedecin", medata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      const medata = this.$store.state.medl.medecin;
      const medataStr = JSON.stringify(medata);
      if (localStorage.medloaded != medataStr) {
        let dataObj = JSON.parse(localStorage.medloaded);
        dataObj.photo = medata.photo; // évite de reprendre l'ancienne photo si elle a été changée avant l'annulation
        this.$store.commit("medl/setMedecin", dataObj);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
  },
  watch: {
    medecinLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    medecinUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.medecinUpdateStatus == 2) {
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        localStorage.medloaded = JSON.stringify(this.$store.state.medl.medecin);
      } else if (this.medecinUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
